<template>
  <div
    class="modal modal__right"
    id="addFieldModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    v-if="show"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#addFieldModal"
                class="modal__close modal__close--black"
                @click="$emit('close')"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              {{ editingCustomField.isEditing ? "Edit Field" : "Add Field" }}
            </h5>
          </div>
          <div class="modal__body">
            <slot name="modal-body"></slot>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "addField",
  props: {
    show: Boolean,
    close: Function,
    editingCustomField: [Object, Array],
  },
};
</script>
